<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

import VueHtml2pdf from "vue-html2pdf";
export default {
  page: {
    title: "Souscription",
    meta: [{ name: "description", content: appConfig.description }],
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    Layout,
    PageHeader,

    VueHtml2pdf,
  },
  data() {
    return {
      title: "Souscription",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      showDetail: false,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Détail souscription",
          active: true,
        },
      ],
      idAssurance: 0,
      idFacture: "",
      numUser: "",
      numberUserCode: "",
      emailUser: "",
      departTrajet: "",
      pointRencontreDepart: "",
      arriveeTrajet: "",
      pointArriveTrajet: "",
      description: "",
      quantite: 0,
      prixunitaireTrajet: 0,
      totalTrajet: 0,
      descriptionAssurance: "",
      quantiteAssurance: 0,
      PrixUnitaireAssurance: 0,
      totalAssurance: 0,
      totalHT: 0,
      tva: 0.18,
      totalTTC: 0,
      nameUser: "",
      userAdresse: "",
      depart: "",
      arrive: "",
      cout: 0,
      dateReserv: "",
      couTAssurance: 0,
      clientIdentifiant: "",
      trajetIdentifiant: "",
      canPrint: false,

      ttc: 0,
      coutHT: 0,
      coutHTPublication: 0,
      arrondi_value:0,
      nbrPlace:0,
      la_tva :0,
      commission:0,
      for_a_person:0
    };
  },
  validations: {},

  mounted() {
    this.idAssurance = this.$route.params.idUser;

    this.loadData();

    setTimeout(() => {
      this.verifyPayement();
    }, 4000);
  },
  methods: {
    async loadData() {
      this.totalRecette = 0;
      const assurrer = await apiRequest(
        "GET",
        "assurance/list/" + this.idAssurance,
        false
      );

      if (assurrer && assurrer.data) {
        let assurera = assurrer.data[0];

       
        this.nameUser = `${assurera.client_id.firstname} `;

        this.idFacture = `${assurera.id}-${assurera.client_id.id}-${assurera.trajet_id.id}`;
        this.userAdresse = assurera.client_id.adresse;
        this.numUser = assurera.client_id.tel;
        this.numberUserCode = assurera.client_id.indicatifContactTel;
        this.couTAssurance = assurera.montant;
        this.clientIdentifiant = assurera.client_id.id;
        this.trajetIdentifiant = assurera.trajet_id.id;
        (this.depart = assurera.trajet_id.lieuDepart),
          (this.arrive = assurera.trajet_id.lieuArrivee),
         
          (this.dateReserv = new Date(assurera.date_assurance).toLocaleString(
            "fr-FR"
          ));
        this.coutHTPublication = assurera.trajet_id.prixPublie;
        this.arrondi_value= assurera.trajet_id.arrondi
        this.la_tva =assurera.trajet_id.tva
        this.commission=assurera.trajet_id.commission

        this.for_a_person = assurera.trajet_id.prixPublie

       
      }
    },

    async verifyPayement() {
     
      const verify = await apiRequest(
        "POST",
        "get-facture",
        { idClient: this.clientIdentifiant, idTrajet: this.trajetIdentifiant },
        false
      );

      if (verify && verify.data) {
        this.canPrint = verify.data.estPayer;
   
        this.couTAssurance = verify.data.montantAssurance;
       

        this.nbrPlace=  verify.data.nbrPlaceReserve;
        this.cout =this.nbrPlace*this.for_a_person;

      }
    },

    /*     scanFacture(item) {
      this.nameUser = item.client;
      this.showDetail = true;
      this.idFacture = `${item.id}-${item.idClient.id}-${item.idTrajet.id}`;
      this.userAdresse = item.idClient.adresse;
      this.numUser = item.idClient.tel;
      this.numberUserCode = item.idClient.indicatifContactTel;

      (this.depart = item.idTrajet.lieuDepart),
        (this.arrive = item.idTrajet.lieuArrivee),
        (this.cout = item.idTrajet.prix),
        (this.dateReserv = new Date(item.date_assurance).toLocaleString(
              "fr-FR"
            ));
            this.couTAssurance=item.montant

         
    },
 */
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },

  /*  
     let  date =Number(item.date_assurance.split(" ")[0].split('/')[1])
        
            return date===(Number(val.id)); 
   
        }


      }
      */
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div
          style="white-space: nowrap; margin: 0 auto"
          v-if="canPrint"
          @click="generatePDF()"
          class="np-btn"
        >
          Imprimer en PDF
        </div>

        <div class="p-1">
          <table
            id="tablePrint"
            data-module="table 02"
            border="0"
            width="100%"
            cellpadding="0"
            cellspacing="0"
            style="padding: 0; background: #ffffff"
            class="currentTable mt-2"
          >
            <tr valign="top">
              <td class="editable">
                <table
                  width="1000px"
                  style="
                    padding: 30px;
                    margin: 0 auto 0 auto;
                    width: 1000px;
                    background: #ffffff;
                  "
                  cellpadding="0"
                  cellspacing="0"
                  bgcolor="#fff"
                >
                  <tr valign="top">
                    <td>
                      <h1
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 20px;
                          text-transform: capitalize;
                        "
                        align="center"
                        data-color="Headline01"
                        data-size="Headline01"
                        data-min="20"
                        data-max="26"
                      >
                        {{ new Date().toLocaleString("fr-FR") }}
                      </h1>
                      <div
                        style="
                          height: 1px;
                          background: #37b24d;
                          width: 100px;
                          margin: 0 auto 0 auto;
                        "
                      ></div>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td style="font-size: 0; line-height: 0" height="20">
                      &nbsp;
                    </td>
                  </tr>
                  <tr valign="top">
                    <td style="padding: 0; width: 100%; border: 1px solid #ddd">
                      <!-- ///////////////////////////////// table payment ///////////////////////////////// -->

                      <table
                        border="0"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="padding: 0; margin: 0"
                      >
                        <tr>
                          <td
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              padding: 8px 5px;
                              background: #37b24d;
                              color: #fff;
                            "
                            align="center"
                          >
                            Facture
                          </td>
                          <td
                            colspan="8"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 12px;
                              border-top: 1px solid #fff;
                              border-right: 1px solid #fff;
                              padding: 8px 5px;
                              background: #37b24d;
                              color: #fff;
                            "
                            width="100%"
                            align="left"
                          >
                            {{ idFacture }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6">
                            <table
                              border="0"
                              width="100%"
                              cellpadding="0"
                              cellspacing="0"
                              style="padding: 0; margin: 0"
                            >
                              <tr valign="top">
                                <td style="border-right: 1px solid #ddd">
                                  <div
                                    style="
                                      font-family: Open Sans, Arial, Helvetica,
                                        sans-serif;
                                      font-weight: 300;
                                      font-size: 13px;
                                      line-height: 1.4em;
                                      border-bottom: 1px solid #ddd;
                                      padding: 8px 5px;
                                    "
                                    width="100%"
                                    align="left"
                                  >
                                    DE
                                  </div>
                                  <div
                                    style="
                                      font-family: Ruda, Arial, Helvetica,
                                        sans-serif;
                                      font-weight: 300;
                                      font-size: 11px;
                                      line-height: 1.4em;
                                      border-top: 1px solid #fff;
                                      border-left: 1px solid #fff;
                                      padding: 8px 5px;
                                      background: #fff;
                                      color: #333;
                                    "
                                    width="100%"
                                    align="left"
                                  >
                                    RAYNIS RMOBILITY<br />imm pharmacie Zogbo,
                                    Zogbo Cotonou<br />Téléphone: (+229)
                                    51390971
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style="
                                      font-family: Open Sans, Arial, Helvetica,
                                        sans-serif;
                                      font-weight: 300;
                                      font-size: 13px;
                                      line-height: 1.4em;
                                      border-bottom: 1px solid #ddd;
                                      padding: 8px 5px;
                                    "
                                    width="100%"
                                    align="right"
                                  >
                                    À
                                  </div>
                                  <div
                                    style="
                                      font-family: Ruda, Arial, Helvetica,
                                        sans-serif;
                                      font-weight: 300;
                                      font-size: 11px;
                                      line-height: 1.4em;
                                      border-top: 1px solid #fff;
                                      border-left: 1px solid #fff;
                                      padding: 8px 5px;
                                      background: #fff;
                                      color: #333;
                                    "
                                    width="100%"
                                    align="right"
                                  >
                                    {{ nameUser }}<br />{{ userAdresse
                                    }}<br />Téléphone: ({{ numberUserCode }})
                                    {{ numUser }}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 12px;
                              background-color: #ccc;
                              color: #fff;
                              line-height: 22px;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 5px;
                              width: 15%;
                            "
                            align="center"
                          >
                            Intitulé
                          </td>
                          <td
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 12px;
                              background-color: #ccc;
                              color: #fff;
                              line-height: 22px;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 5px;
                              width: 15%;
                            "
                            align="center"
                          >
                            Date
                          </td>
                          <td
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 12px;
                              background-color: #ccc;
                              color: #fff;
                              line-height: 22px;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 5px;
                              width: 25%;
                            "
                            align="center"
                          >
                            Départ
                          </td>
                          <td
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 12px;
                              background-color: #ccc;
                              color: #fff;
                              line-height: 22px;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 5px;
                              width: 25%;
                            "
                            align="left"
                          >
                            Arrivé
                          </td>
                          <td
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 12px;
                              background-color: #ccc;
                              color: #fff;
                              line-height: 22px;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 5px;
                              width: 30%;
                            "
                            align="right"
                          >
                            Coût en FCFA
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 13px;
                              line-height: 1.4em;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                            "
                            align="center"
                          >
                            Réservation de trajet
                          </td>
                          <td
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 13px;
                              line-height: 1.4em;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                            "
                            align="center"
                          >
                            {{ dateReserv }}
                          </td>
                          <td
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 13px;
                              line-height: 1.4em;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                            "
                            align="left"
                          >
                            {{ depart }}
                          </td>
                          <td
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 13px;
                              line-height: 1.4em;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                            "
                            align="right"
                          >
                            {{ arrive }}
                          </td>
                          <td
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 13px;
                              line-height: 1.4em;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                            "
                            align="center"
                          >
                            {{ cout }}
                          </td>
                        </tr>

                        <tr>
                          <td
                            colspan="3"
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                          ></td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            Coût assurance
                          </td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            {{ couTAssurance }} FCFA
                          </td>
                        </tr>

                        <!--                         <tr>
                          <td
                            colspan="3"
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                          ></td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            Total HT
                          </td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            {{ cout + couTAssurance }} FCFA
                          </td>
                        </tr>
 -->
                        <tr>
                          <td
                            colspan="3"
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                          ></td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            Commission
                          </td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            {{  Math.round((commission+arrondi_value )*nbrPlace)}}
                            
                            
                          </td>
                        </tr>

                        <tr>
                          <td
                            colspan="3"
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                          ></td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            TVA 18%
                          </td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            {{ Math.round( this.nbrPlace*( la_tva) ) }}
                            FCFA
                          </td>
                        </tr>

                        <tr>
                          <td
                            colspan="3"
                            style="
                              font-family: Ruda, Arial, Helvetica, sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                          ></td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            Total TTC
                          </td>
                          <td
                            colspan="1"
                            style="
                              font-family: Open Sans, Arial, Helvetica,
                                sans-serif;
                              font-weight: 300;
                              font-size: 15px;
                              line-height: 1.4em;
                              border-top: 1px solid #fff;
                              border-bottom: 1px solid #ddd;
                              padding: 8px 5px;
                              background-color: #f8f8f8;
                            "
                            align="right"
                          >
                            {{
                              Math.round(
                              cout +
                              couTAssurance +
                              Math.round( this.nbrPlace*( la_tva) )+
                              Math.round((commission+arrondi_value )*nbrPlace)
                              )
                            }}
                            FCFA
                          </td>
                        </tr>
                      </table>
                      <!-- ///////////////////////////////// table payment end ///////////////////////////////// -->
                    </td>
                  </tr>
                  <tr valign="top">
                    <td style="font-size: 0; line-height: 0" height="20">
                      &nbsp;
                    </td>
                  </tr>
                  <tr valign="top">
                    <td>
                      <div
                        data-color="ContentTable02"
                        data-size="ContentTable02"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-size: 14px;
                          font-weight: 300;
                          line-height: 1.4em;
                        "
                        align="left"
                      >
                        Nous vous remercions de votre confiance.
                      </div>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td style="font-size: 0; line-height: 0" height="20">
                      &nbsp;
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="nightprogrammerpdf"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          :pdf-margin="10"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @progress="onProgress($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <table
              id="tablePrint"
              data-module="table 02"
              border="0"
              width="100%"
              cellpadding="0"
              cellspacing="0"
              style="padding: 0; background: #ffffff"
              class="currentTable"
            >
              <tr valign="top">
                <td class="editable">
                  <table
                    width="800px"
                    style="
                      padding: 30px;
                      margin: 0 auto 0 auto;
                      width: 750px;
                      background: #ffffff;
                    "
                    cellpadding="0"
                    cellspacing="0"
                    bgcolor="#fff"
                  >
                    <tr valign="top">
                      <td></td>
                    </tr>
                    <tr valign="top">
                      <td style="font-size: 0; line-height: 0" height="20">
                        &nbsp;
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style="padding: 0; width: 100%; border: 1px solid #ddd"
                      >
                        <!-- ///////////////////////////////// table payment ///////////////////////////////// -->

                        <table
                          border="0"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          style="padding: 0; margin: 0"
                        >
                          <tr>
                            <td
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: bold;
                                font-size: 12px;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                padding: 8px 5px;
                                background: #37b24d;
                                color: #fff;
                              "
                              align="center"
                            >
                              Facture
                            </td>
                            <td
                              colspan="8"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 12px;
                                border-top: 1px solid #fff;
                                border-right: 1px solid #fff;
                                padding: 8px 5px;
                                background: #37b24d;
                                color: #fff;
                              "
                              width="100%"
                              align="left"
                            >
                              {{ idFacture }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6">
                              <table
                                border="0"
                                width="100%"
                                cellpadding="0"
                                cellspacing="0"
                                style="padding: 0; margin: 0"
                              >
                                <tr valign="top">
                                  <td style="border-right: 1px solid #ddd">
                                    <div
                                      style="
                                        font-family: Open Sans, Arial, Helvetica,
                                          sans-serif;
                                        font-weight: 300;
                                        font-size: 13px;
                                        line-height: 1.4em;
                                        border-bottom: 1px solid #ddd;
                                        padding: 8px 5px;
                                      "
                                      width="100%"
                                      align="left"
                                    >
                                      DE
                                    </div>
                                    <div
                                      style="
                                        font-family: Ruda, Arial, Helvetica,
                                          sans-serif;
                                        font-weight: 300;
                                        font-size: 11px;
                                        line-height: 1.4em;
                                        border-top: 1px solid #fff;
                                        border-left: 1px solid #fff;
                                        padding: 8px 5px;
                                        background: #fff;
                                        color: #333;
                                      "
                                      width="100%"
                                      align="left"
                                    >
                                      RAYNIS RMOBILITY<br />imm pharmacie Zogbo,
                                      Zogbo Cotonou<br />Téléphone: (+229)
                                      51390971
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style="
                                        font-family: Open Sans, Arial, Helvetica,
                                          sans-serif;
                                        font-weight: 300;
                                        font-size: 13px;
                                        line-height: 1.4em;
                                        border-bottom: 1px solid #ddd;
                                        padding: 8px 5px;
                                      "
                                      width="100%"
                                      align="right"
                                    >
                                      À
                                    </div>
                                    <div
                                      style="
                                        font-family: Ruda, Arial, Helvetica,
                                          sans-serif;
                                        font-weight: 300;
                                        font-size: 11px;
                                        line-height: 1.4em;
                                        border-top: 1px solid #fff;
                                        border-left: 1px solid #fff;
                                        padding: 8px 5px;
                                        background: #fff;
                                        color: #333;
                                      "
                                      width="100%"
                                      align="right"
                                    >
                                      {{ nameUser }}<br />{{ userAdresse
                                      }}<br />Téléphone: ({{ numberUserCode }})
                                      {{ numUser }}
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 12px;
                                background-color: #ccc;
                                color: #fff;
                                line-height: 22px;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 5px;
                                width: 15%;
                              "
                              align="center"
                            >
                              Intitulé
                            </td>
                            <td
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 12px;
                                background-color: #ccc;
                                color: #fff;
                                line-height: 22px;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 5px;
                                width: 15%;
                              "
                              align="center"
                            >
                              Date
                            </td>
                            <td
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 12px;
                                background-color: #ccc;
                                color: #fff;
                                line-height: 22px;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 5px;
                                width: 25%;
                              "
                              align="center"
                            >
                              Départ
                            </td>
                            <td
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 12px;
                                background-color: #ccc;
                                color: #fff;
                                line-height: 22px;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 5px;
                                width: 25%;
                              "
                              align="left"
                            >
                              Arrivé
                            </td>
                            <td
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 12px;
                                background-color: #ccc;
                                color: #fff;
                                line-height: 22px;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 5px;
                                width: 30%;
                              "
                              align="right"
                            >
                              Coût en FCFA
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 1.4em;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                              "
                              align="center"
                            >
                              Réservation de trajet
                            </td>
                            <td
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 1.4em;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                              "
                              align="center"
                            >
                              {{ dateReserv }}
                            </td>
                            <td
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 1.4em;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                              "
                              align="left"
                            >
                              {{ depart }}
                            </td>
                            <td
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 1.4em;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                              "
                              align="right"
                            >
                              {{ arrive }}
                            </td>
                            <td
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 1.4em;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                              "
                              align="center"
                            >
                              {{ cout }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="3"
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                            ></td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              Coût assurance
                            </td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              {{ couTAssurance }} FCFA
                            </td>
                          </tr>

                          <!--   <tr>
                            <td
                              colspan="3"
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                            ></td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              Total
                            </td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              {{ cout + couTAssurance }} FCFA
                            </td>
                          </tr>
 -->
                          <tr>
                            <td
                              colspan="3"
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                            ></td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              Commission
                            </td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                   
                              {{ (coutHTPublication * 15) / 100 }}
                            </td>
                          </tr>

                          <tr>
                            <td
                              colspan="3"
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                            ></td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              TVA 18%
                            </td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              {{ ((coutHTPublication * 15) / 100) * 0.18 }}
                              FCFA
                            </td>
                          </tr>

                          <tr>
                            <td
                              colspan="3"
                              style="
                                font-family: Ruda, Arial, Helvetica, sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-left: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                            ></td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              Total TTC
                            </td>
                            <td
                              colspan="1"
                              style="
                                font-family: Open Sans, Arial, Helvetica,
                                  sans-serif;
                                font-weight: 300;
                                font-size: 15px;
                                line-height: 1.4em;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #ddd;
                                padding: 8px 5px;
                                background-color: #f8f8f8;
                              "
                              align="right"
                            >
                              {{
                                cout +
                                couTAssurance +
                                (coutHTPublication * 15) / 100 +
                                ((coutHTPublication * 15) / 100) * 0.18
                              }}
                              FCFA
                            </td>
                          </tr>
                          <!--  <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Total
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-right: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        $840
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Disc (10%)
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-right: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        $84
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Shipping
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-right: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        $25
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border: 1px solid #fff;
                          padding: 10px 5px;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border: 1px solid #fff;
                          padding: 10px 5px;
                        "
                        align="right"
                      >
                        Grand Total
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 1.4em;
                          letter-spacing: 1px;
                          border: 1px solid #fff;
                          background-color: #37b24d;
                          color: #fff;
                          padding: 10px 5px;
                        "
                        align="right"
                      >
                        $781
                      </td>
                    </tr> -->
                        </table>
                        <!-- ///////////////////////////////// table payment end ///////////////////////////////// -->
                      </td>
                    </tr>
                    <tr valign="top">
                      <td style="font-size: 0; line-height: 0" height="20">
                        &nbsp;
                      </td>
                    </tr>
                    <tr valign="top">
                      <td>
                        <div
                          data-color="ContentTable02"
                          data-size="ContentTable02"
                          style="
                            font-family: Open Sans, Arial, Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 1.4em;
                          "
                          align="left"
                        >
                          Nous vous remercions de votre confiance.
                        </div>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td style="font-size: 0; line-height: 0" height="20">
                        &nbsp;
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </section>
        </vue-html2pdf>
        <div></div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.imageCard {
  display: flex;
  align-items: center;
  flex-direction: column;
}

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #37b24d;
  width: 140px;
  background: #37b24d;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
</style>
